import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div>
          <img style={{width: "150px"}} src="./logo.png" alt="" />
      </div>
      <div className="wrapper">
        <h1 >coming soon<span className="dot">.</span></h1>
        <p>Are you looking jobs in non IT sectors such as HR, Operation, finance and etc. <br /> Scan this QR code and fill just one form and get your first interview call soon!</p>
        <div>
          <img style={{width: "200px"}} src="./formqr.jpeg" alt="" />
        </div>
      </div>
    </div>
  );
}

export default App;
